import { InitForm } from 'custom-form-lib';
import { devsecopsSections } from 'src/app/forms-sections/devsecops-sections';
import * as Sentry from '@sentry/angular';
import packageInfo from '../../package.json';
import { fullstackSections } from 'src/app/forms-sections/fullstack-sections';

const applyDevSecOpsConfig: InitForm = {
    sections: devsecopsSections,
    testing: false,
    applyFullForm: false,
    currentSection: 0,
    endPoint: 'https://staging-register-form.developerakademie.com/leads/',
    estimatedMinutes: 5,
    timeStampIdentifier: 'sessionTimestampDevSecOps',
};
const applyFullStackConfig: InitForm = {
    sections: fullstackSections,
    testing: false,
    applyFullForm: false,
    currentSection: 0,
    endPoint: './postTunnel.php',
    estimatedMinutes: 5,
};
const sentryPartialConf: Partial<Sentry.BrowserOptions> = {
    tunnel: './sentry.php',
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    debug: true,
    tracesSampleRate: 0.5,
    tracePropagationTargets: [
        'https://staging-weiterbildung.developerakademie.com',
    ],
};
export const environment = {
    production: true,
    envName: 'staging',
    applyDevSecOpsConfig,
    applyFullStackConfig,
    sentryPartialConf,
    appName: packageInfo.name,
    appVersion: packageInfo.version,
};
